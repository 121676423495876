import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import CustomTab from '@components/pages/max/design2024/SectionIncreasedSales/Components/CustomTab';
import Industry from '@components/pages/max/design2024/SectionIncreasedSales/Components/Industry';
import Link from '@components/pages/max/design2024/SectionIncreasedSales/Components/Link';
import Sales from '@components/pages/max/design2024/SectionIncreasedSales/Components/Sales';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { multisiteRouteCustomersPostPakoLorente } from '@static_components/multisiteLinks';

import styles from './PakoLorente.module.css';
import imgAVIF from './assets/img@1x.avif';
import imgPNG from './assets/img@1x.png';
import imgWebP1x from './assets/img@1x.webp';
import imgAVIF2x from './assets/img@2x.avif';
import imgPNG2x from './assets/img@2x.png';
import imgWebP2x from './assets/img@2x.webp';
import logoAVIF from './assets/logo@1x.avif';
import logoPNG from './assets/logo@1x.png';
import logoWebP1x from './assets/logo@1x.webp';
import logoAVIF2x from './assets/logo@2x.avif';
import logoPNG2x from './assets/logo@2x.png';
import logoWebP2x from './assets/logo@2x.webp';

const ns = 'pages/max/design2024/components/SectionIncreasedSales/index';
export function PakoLorente() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <CustomTab
            caseStudies={{
                logo: (
                    <picture>
                        <source
                            type="image/avif"
                            srcSet={`${logoAVIF.src} 1x, ${logoAVIF2x.src} 2x`}
                        />
                        <source
                            type="image/webp"
                            srcSet={`${logoWebP1x.src} 1x, ${logoWebP2x.src} 2x`}
                        />
                        <source srcSet={`${logoPNG.src} 1x, ${logoPNG2x.src} 2x`} />
                        <img
                            width={logoPNG.width}
                            height={logoPNG.height}
                            decoding="async"
                            loading="lazy"
                            alt=""
                            src={logoPNG.src}
                        />
                    </picture>
                ),
                desc: (
                    <Trans
                        i18nKey="increasedSalesItem1BusinessDesc"
                        components={{
                            p: <p />,
                            br: <br />,
                        }}
                        ns={ns}
                    />
                ),
                link: (
                    <>
                        <Button
                            href={multisiteRouteCustomersPostPakoLorente(locale)}
                            label={t('increasedSalesItemsButtonLabel')}
                            color="ghost-blue"
                        />
                        <div className={styles.action}>
                            <Link
                                arrow={true}
                                label={t('linkLablelCustomersStories')}
                                customLink={<CustomLink href={{ pathname: 'customers' }} />}
                            />
                        </div>
                    </>
                ),
            }}
            picture={
                <picture className={styles.img}>
                    <source
                        type="image/avif"
                        srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
                    />
                    <source
                        type="image/webp"
                        srcSet={`${imgWebP1x.src} 1x, ${imgWebP2x.src} 2x`}
                    />
                    <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
                    <img
                        width={imgPNG.width}
                        height={imgPNG.height}
                        decoding="async"
                        loading="lazy"
                        alt=""
                        src={imgPNG.src}
                    />
                </picture>
            }
            industry={
                <>
                    <Industry industry={t('increasedSalesItem1BusinessIndustryItems')} />
                </>
            }
            sales={
                <>
                    <Sales
                        number={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem1Number"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                        desc={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem1Desc"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                    />
                    <Sales
                        number={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem2Number"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                        desc={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem2Desc"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                    />
                    <Sales
                        number={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem3Number"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                        desc={
                            <Trans
                                i18nKey="increasedSalesItem1BusinessSalesItem3Desc"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                    />
                </>
            }
        />
    );
}
