import React from 'react';

import Head from 'next/head';

import useTranslation from '@hooks/useTranslation';

import Heroavif from './hero@1x.avif';
import Heropng from './hero@1x.png';
import Herowebp from './hero@1x.webp';
import Heroavif2x from './hero@2x.avif';
import Heropng2x from './hero@2x.png';
import Herowebp2x from './hero@2x.webp';

interface HeroImageProps {
    lazy?: boolean;
    fetchpriority?: boolean;
}

export default function HeroImage({ lazy = true, fetchpriority = false }: HeroImageProps): JSX.Element {
    const ns = 'pages/max/design2024/components/SectionHero/index';
    const { t } = useTranslation(ns);

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    fetchpriority="high" // eslint-disable-line
                    href={Heroavif.src}
                    type="image/avif"
                    as="image"
                    imageSrcSet={`${Heroavif.src} ${Heroavif.width}w, ${Heroavif2x.src} ${Heroavif2x.width}w`}
                    imageSizes={`(min-width: ${Heroavif.width}px) ${Heroavif.width}px, 100vw`}
                />
                <link
                    rel="preload"
                    fetchpriority="high" // eslint-disable-line
                    href={Herowebp.src}
                    type="image/webp"
                    as="image"
                    imageSrcSet={`${Herowebp.src} ${Herowebp.width}w, ${Herowebp2x.src} ${Herowebp2x.width}w`}
                    imageSizes={`(min-width: ${Herowebp.width}px) ${Herowebp.width}px, 100vw`}
                />
            </Head>
            <picture>
                <source
                    srcSet={`${Herowebp.src} 1x, ${Herowebp2x.src} 2x`}
                    type="image/webp"
                />
                <source
                    type="image/avif"
                    srcSet={`${Heroavif.src} 1x, ${Heroavif2x.src} 2x`}
                />
                <source srcSet={`${Heropng.src} 1x, ${Heropng2x.src} 2x`} />
                <img
                    width={Heropng.width}
                    height={Heropng.height}
                    decoding="async"
                    loading={lazy ? 'lazy' : undefined}
                    fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                    alt={t('imgAlt')}
                    src={Heropng.src}
                />
            </picture>
        </>
    );
}
