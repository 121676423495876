import { FunctionComponent, cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Article, { Paragraph } from '@components/global/Article';
import { Tab } from '@components/pages/max/TabsAccordion';
import { Tabs } from '@components/pages/max/TabsAccordion/Tabs/themed/default';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteMaxCrossChannelCommunication,
    laravelRouteMaxEngagementAndRetention,
    laravelRouteMaxIndustrySolutionsECommerce,
    laravelRouteMaxLeadAcquisition,
} from '@static_components/laravelLinks';
import { multisiteRouteHelpPostHowGoodIsYourEmailDeliverability } from '@static_components/multisiteLinks';

import CustomTab from './CustomTab';
import styles from './SectionAllInOnePlatform.module.css';
import { ReactComponent as SVGTab1 } from './assets/icons/new/tab1.svg';
import { ReactComponent as SVGTab2 } from './assets/icons/new/tab2.svg';
import { ReactComponent as SVGTab3 } from './assets/icons/new/tab3.svg';
import { ReactComponent as SVGTab4 } from './assets/icons/new/tab4.svg';
import TabPicture1 from './assets/tab1';
import TabPicture2 from './assets/tab2';
import TabPicture3 from './assets/tab3';
import TabPicture4 from './assets/tab4';

const ns = 'pages/max/design2024/components/SectionAllInOnePlatform/index';

const Content: FunctionComponent = () => {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();

    return (
        <div className={styles['section-all-in-one-platform']}>
            <Article
                align="center"
                className={styles['article-all-in-one-platform']}
                title={
                    <Trans
                        i18nKey="title"
                        components={{
                            span: <span />,
                            br: <DeskoptBr />,
                        }}
                        ns={ns}
                    />
                }
                articleContent={
                    <Paragraph variant="subheader">
                        <Trans
                            i18nKey="subtitle"
                            components={{
                                strong: <strong />,
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    </Paragraph>
                }
            />
            <Tabs
                type="tabs"
                arrows
                accordion
                classNames={styles}
                tabDirection="row"
            >
                <Tab
                    active={true}
                    title={
                        <Trans
                            i18nKey="tab1Button"
                            components={{
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    }
                    icon={
                        <SVGTab1
                            width={64}
                            height={64}
                        />
                    }
                >
                    <CustomTab
                        article={
                            <Article
                                articleContent={
                                    <>
                                        <Trans
                                            i18nKey="tab1Description"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                p: <Paragraph />,
                                            }}
                                            ns={ns}
                                        />
                                        <Trans
                                            i18nKey="tab1List"
                                            components={{
                                                URL: (
                                                    <a
                                                        href={multisiteRouteHelpPostHowGoodIsYourEmailDeliverability(
                                                            locale,
                                                        )}
                                                    />
                                                ),
                                                strong: <strong />,
                                                b: <b />,
                                                ul: <ul />,
                                                li: <li />,
                                            }}
                                            ns={ns}
                                        />
                                        <Button
                                            label={t('BTNLearnMore')}
                                            color="ghost-blue"
                                            href={laravelRouteMaxEngagementAndRetention(locale)}
                                        />
                                    </>
                                }
                            />
                        }
                        picture={<TabPicture1 />}
                    />
                </Tab>
                <Tab
                    title={
                        <Trans
                            i18nKey="tab2Button"
                            components={{
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    }
                    icon={
                        <SVGTab2
                            width={64}
                            height={64}
                        />
                    }
                >
                    <CustomTab
                        article={
                            <Article
                                articleContent={
                                    <>
                                        <Trans
                                            i18nKey="tab2Description"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                p: <Paragraph />,
                                            }}
                                            ns={ns}
                                        />
                                        <Trans
                                            i18nKey="tab2List"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                ul: <ul />,
                                                li: <li />,
                                            }}
                                            ns={ns}
                                        />
                                        <Button
                                            label={t('BTNLearnMore')}
                                            color="ghost-blue"
                                            href={laravelRouteMaxCrossChannelCommunication(locale)}
                                        />
                                    </>
                                }
                            />
                        }
                        picture={<TabPicture2 />}
                    />
                </Tab>
                <Tab
                    title={
                        <Trans
                            i18nKey="tab3Button"
                            components={{
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    }
                    icon={
                        <SVGTab3
                            width={64}
                            height={64}
                        />
                    }
                >
                    <CustomTab
                        article={
                            <Article
                                articleContent={
                                    <>
                                        <Trans
                                            i18nKey="tab3Description"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                p: <Paragraph />,
                                            }}
                                            ns={ns}
                                        />
                                        <Trans
                                            i18nKey="tab3List"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                ul: <ul />,
                                                li: <li />,
                                            }}
                                            ns={ns}
                                        />
                                        <Button
                                            label={t('BTNLearnMore')}
                                            color="ghost-blue"
                                            href={laravelRouteMaxLeadAcquisition(locale)}
                                        />
                                    </>
                                }
                            />
                        }
                        picture={<TabPicture3 />}
                    />
                </Tab>
                <Tab
                    title={
                        <Trans
                            i18nKey="tab4Button"
                            components={{
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    }
                    icon={
                        <SVGTab4
                            width={64}
                            height={64}
                        />
                    }
                >
                    <CustomTab
                        article={
                            <Article
                                articleContent={
                                    <>
                                        <Trans
                                            i18nKey="tab4Description"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                p: <Paragraph />,
                                            }}
                                            ns={ns}
                                        />
                                        <Trans
                                            i18nKey="tab4List"
                                            components={{
                                                strong: <strong />,
                                                b: <b />,
                                                ul: <ul />,
                                                li: <li />,
                                            }}
                                            ns={ns}
                                        />
                                        <Button
                                            label={t('BTNLearnMore')}
                                            color="ghost-blue"
                                            href={laravelRouteMaxIndustrySolutionsECommerce(locale)}
                                        />
                                    </>
                                }
                            />
                        }
                        picture={<TabPicture4 />}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

interface SectionAllInOnePlatformProps {
    container: React.ReactElement;
}

export default function SectionAllInOnePlatform({ container }: SectionAllInOnePlatformProps): JSX.Element {
    return (
        <section className={styles['business-needs']}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1180px',
            })}
        </section>
    );
}
