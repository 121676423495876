import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { BFCM2024GtmEvent } from '@components/global/PromoBlackFriday2024/utils';
import MenuButton from '@components/layouts/Header/MenuButton';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';
import clsx from 'clsx';

import defaultStyles from './MaxHeaderButton.module.css';

export interface MaxHeaderButtonProps {
    type: 'header' | 'subheader';
    buttonVisible?: Dispatch<SetStateAction<boolean>>;
    className?: string;
    themeStyles?: typeof defaultStyles;
    isPromoActive?: boolean;
}

export default function MaxHeaderButton({
    type,
    buttonVisible,
    className,
    isPromoActive,
    themeStyles,
}: MaxHeaderButtonProps): JSX.Element {
    const { t } = useTranslation('pages/max/index');
    const { locale } = useRouter();
    const customMenuButton = useRef<HTMLAnchorElement>(null);

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }

    useEffect(() => {
        const handleScroll = () => {
            const headerButton = customMenuButton.current;
            if (headerButton != null) {
                const breakPosition = Math.max(headerButton.getBoundingClientRect().top + window.scrollY - 200, 1);
                if (type === 'header') {
                    if (window.scrollY > breakPosition) {
                        headerButton.classList.remove(styles.bounceIn);
                        headerButton.classList.add(styles.zoomOut);
                    } else {
                        headerButton.classList.add(styles.bounceIn);
                        headerButton.classList.remove(styles.zoomOut);
                    }
                } else {
                    if (window.scrollY < breakPosition) {
                        headerButton.classList.remove(styles.bounceIn);
                        headerButton.classList.add(styles.zoomOut);
                        if (buttonVisible) {
                            buttonVisible(false);
                        }
                    } else {
                        headerButton.classList.add(styles.bounceIn);
                        headerButton.classList.remove(styles.zoomOut);
                        if (buttonVisible) {
                            buttonVisible(true);
                        }
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [type, buttonVisible, styles.bounceIn, styles.zoomOut]);

    const handleButtonClick = React.useCallback(() => {
        if (isPromoActive) {
            new BFCM2024GtmEvent({ state: 'buttonClicked', placement: 'homepage-max-navbar' });
        }
    }, [isPromoActive]);

    return (
        <MenuButton>
            <a
                href={laravelRouteMaxBookADemo(locale)}
                data-ats-max-menu-button="book_a_demo"
                data-track="create_free_trial_link"
                title={t('nav.book_a_demo')}
                className={clsx(styles.button, className)}
                onClick={handleButtonClick}
                ref={customMenuButton}
            >
                {t('nav.book_a_demo')}
            </a>
        </MenuButton>
    );
}
