import React, { useState } from 'react';

import { useRouter } from 'next/router';

import Container from '@components/global/Container';
import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteMaxIndustrySolutions,
    laravelRouteMaxIntegrations,
    laravelRouteMaxSupportAndSecurity,
} from '@static_components/laravelLinks';
import clsx from 'clsx';

import defaultStyles from './MaxHeader.module.css';
import MaxHeaderButtonDefault from './MaxHeaderButton/themed/default';
import MaxHeaderButtonLegacy from './MaxHeaderButton/themed/legacy';
import MaxHeaderItemDefault from './MaxHeaderItem/themed/default';
import MaxHeaderItemLegacy from './MaxHeaderItem/themed/legacy';

export interface MaxHeaderProps {
    themeStyles?: typeof defaultStyles;
    theme?: 'default' | 'legacy';
    isPromoActive?: boolean;
}

const MaxHeader = ({ themeStyles, theme, isPromoActive }: MaxHeaderProps) => {
    const { t } = useTranslation('pages/max/index');
    const [buttonVisible, setButtonVisible] = useState<boolean>(false);
    const { locale, pathname } = useRouter();

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }

    return (
        <section className={clsx(styles['max-nav'], buttonVisible && styles.visible)}>
            <Container>
                <nav className={clsx(styles.nav, buttonVisible && styles.visible)}>
                    <ul>
                        {theme === 'legacy' ? (
                            <>
                                <MaxHeaderItemLegacy
                                    label={
                                        pathname !== '/max' ? (
                                            <CustomLink href={{ pathname: 'max' }}>{t('nav.menu_max')}</CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_max')}</span>
                                        )
                                    }
                                />
                                <MaxHeaderItemLegacy
                                    label={
                                        <a href={laravelRouteMaxIndustrySolutions(locale)}>
                                            {t('nav.menu_industries')}
                                        </a>
                                    }
                                />
                                <MaxHeaderItemLegacy
                                    label={
                                        <a href={laravelRouteMaxSupportAndSecurity(locale)}>{t('nav.menu_support')}</a>
                                    }
                                />
                                <MaxHeaderItemLegacy
                                    label={
                                        <a href={laravelRouteMaxIntegrations(locale)}>{t('nav.menu_integrations')}</a>
                                    }
                                />
                                <MaxHeaderItemLegacy
                                    label={
                                        pathname !== '/max/mailchimp-to-max' ? (
                                            <CustomLink href={{ pathname: 'max/mailchimp-to-max' }}>
                                                {t('nav.menu_mailchimp_migration')}
                                            </CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_mailchimp_migration')}</span>
                                        )
                                    }
                                />
                                <MaxHeaderItemLegacy
                                    label={
                                        pathname !== '/max/partners' && pathname !== '/max/partners/versions/b' ? (
                                            <CustomLink href={{ pathname: 'max/partners' }}>
                                                {t('nav.menu_partners')}
                                            </CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_partners')}</span>
                                        )
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <MaxHeaderItemDefault
                                    label={
                                        pathname !== '/max' ? (
                                            <CustomLink href={{ pathname: 'max' }}>{t('nav.menu_max')}</CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_max')}</span>
                                        )
                                    }
                                />
                                <MaxHeaderItemDefault
                                    label={
                                        <a href={laravelRouteMaxIndustrySolutions(locale)}>
                                            {t('nav.menu_industries')}
                                        </a>
                                    }
                                />
                                <MaxHeaderItemDefault
                                    label={
                                        <a href={laravelRouteMaxSupportAndSecurity(locale)}>{t('nav.menu_support')}</a>
                                    }
                                />
                                <MaxHeaderItemDefault
                                    label={
                                        <a href={laravelRouteMaxIntegrations(locale)}>{t('nav.menu_integrations')}</a>
                                    }
                                />
                                <MaxHeaderItemDefault
                                    label={
                                        pathname !== '/max/mailchimp-to-max' ? (
                                            <CustomLink href={{ pathname: 'max/mailchimp-to-max' }}>
                                                {t('nav.menu_mailchimp_migration')}
                                            </CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_mailchimp_migration')}</span>
                                        )
                                    }
                                />
                                <MaxHeaderItemDefault
                                    label={
                                        pathname !== '/max/partners' && pathname !== '/max/partners/versions/b' ? (
                                            <CustomLink href={{ pathname: 'max/partners' }}>
                                                {t('nav.menu_partners')}
                                            </CustomLink>
                                        ) : (
                                            <span>{t('nav.menu_partners')}</span>
                                        )
                                    }
                                />
                            </>
                        )}
                    </ul>
                    {theme === 'legacy' ? (
                        <MaxHeaderButtonLegacy
                            buttonVisible={setButtonVisible}
                            type="subheader"
                            className={styles.button}
                            isPromoActive={isPromoActive}
                        />
                    ) : (
                        <MaxHeaderButtonDefault
                            buttonVisible={setButtonVisible}
                            type="subheader"
                            className={styles.button}
                            isPromoActive={isPromoActive}
                        />
                    )}
                </nav>
            </Container>
        </section>
    );
};

export default MaxHeader;
