import React, { cloneElement } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useMaxThemeContext } from '@context/MaxThemeContex';

import Trans from '@components/Trans';
import StarRate from '@components/global/StarRate';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import Awards from './Awards';
import defaultStyles from './Customers.module.css';
import capterraSVG from './assets/capterra.svg';
import g2SVG from './assets/g2.svg';

const ns = 'pages/max-new/global/_blocks/customers/index';

const StarRateDefault = dynamic(() => import('@components/global/StarRate/themed/default'), {
    ssr: false,
});

const StarRateLegacy = dynamic(() => import('@components/global/StarRate/themed/legacy'), {
    ssr: false,
});

export interface ContentProps {
    sectionHeadline?: string | JSX.Element;
    quote?: {
        customersQuoteLink?: string;
        customersQuoteHeader?: string;
        customersQuote?: string;
        customersQuoteDate?: string;
        customersQuoteLogo?: JSX.Element;
        customerQuoteRatingStar?: number;
        customerQuoteRating?: number;
    }[];
    themeStyles?: typeof defaultStyles;
    maxWidthAwards?: string;
    customClass?: string;
}

const Content = ({ sectionHeadline, quote, themeStyles, maxWidthAwards = '100%', customClass }: ContentProps) => {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    const theme = useMaxThemeContext() ? useMaxThemeContext() : 'legacy';

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }

    const sectionTitle = sectionHeadline ? (
        sectionHeadline
    ) : (
        <Trans
            i18nKey="customersHeader"
            components={{
                mark: <span />,
                span: <span />,
            }}
            ns={ns}
        />
    );

    const customersQuote1Link = quote?.[0]?.customersQuoteLink
        ? quote[0].customersQuoteLink
        : locale === 'de'
        ? 'https://www.capterra.com.de/software/153948/getresponse'
        : 'https://www.capterra.com/p/153948/GetResponse/';
    const customersQuote1Header = quote?.[0]?.customersQuoteHeader
        ? quote[0].customersQuoteHeader
        : t('customersQuoteHeader1');
    const customersQuote1Desc = quote?.[0]?.customersQuote ? quote[0].customersQuote : t('customersQuote1');
    const customerQuote1RatingStar = quote?.[0]?.customerQuoteRatingStar ? quote[0].customerQuoteRatingStar : 4;
    const customersQuote1Rating = quote?.[0]?.customerQuoteRating ? quote[0].customerQuoteRating : 4.2;
    const customersQuote1Date = quote?.[0]?.customersQuoteDate ? quote[0].customersQuoteDate : t('customersQuoteDate1');
    const customersQuote1Logo =
        quote && quote[0] && quote[0].customersQuoteLogo ? (
            quote[0].customersQuoteLogo
        ) : (
            <img
                src={capterraSVG.src}
                width={capterraSVG.width}
                height={capterraSVG.height}
                alt="Capterra"
                className={styles.logo}
            />
        );

    const customersQuote2Link = quote?.[1]?.customersQuoteLink
        ? quote[1].customersQuoteLink
        : 'https://www.g2.com/products/getresponse-max/reviews';
    const customersQuote2Header = quote?.[1]?.customersQuoteHeader
        ? quote[1].customersQuoteHeader
        : t('customersQuoteHeader2');
    const customersQuote2Desc = quote?.[1]?.customersQuote ? quote[1].customersQuote : t('customersQuote2');
    const customerQuote2RatingStar = quote?.[1]?.customerQuoteRatingStar ? quote[1].customerQuoteRatingStar : 5;
    const customersQuote2Rating = quote?.[1]?.customerQuoteRating ? quote[1].customerQuoteRating : 4.2;
    const customersQuote2Date = quote?.[1]?.customersQuoteDate ? quote[1].customersQuoteDate : t('customersQuoteDate2');
    const customersQuote2Logo = quote?.[1]?.customersQuoteLogo ? (
        quote[1].customersQuoteLogo
    ) : (
        <img
            src={g2SVG.src}
            width={g2SVG.width}
            height={g2SVG.height}
            alt="g2"
            className={styles.logo}
        />
    );

    return (
        <div className={clsx(styles.customers, customClass)}>
            <h2>{sectionTitle}</h2>
            <div className={styles.testimonials}>
                <a
                    href={customersQuote1Link}
                    className={styles.testimonial}
                >
                    <q>{customersQuote1Header}</q>
                    <div className={styles.columns}>
                        <div className={styles.quote}>
                            <blockquote>
                                {customersQuote1Desc}
                                <footer>
                                    <StarRate
                                        rate={customerQuote1RatingStar}
                                        color="#20BAFF"
                                    />{' '}
                                    <span className={styles.date}>{customersQuote1Date}</span>
                                </footer>
                            </blockquote>
                        </div>
                        <div className={styles.rate}>
                            {customersQuote1Logo}
                            <div>
                                {theme === 'legacy' ? (
                                    <StarRateLegacy
                                        rate={customersQuote1Rating}
                                        color="#FFC400"
                                    />
                                ) : (
                                    <StarRateDefault
                                        rate={customersQuote1Rating}
                                        color="#FFC400"
                                    />
                                )}
                            </div>
                            <span className={styles.rating}>{customersQuote1Rating} / 5</span>
                        </div>
                    </div>
                </a>
                <a
                    href={customersQuote2Link}
                    className={styles.testimonial}
                >
                    <q>{customersQuote2Header}</q>
                    <div className={styles.columns}>
                        <div className={styles.quote}>
                            <blockquote>
                                {customersQuote2Desc}
                                <footer>
                                    {theme == 'legacy' ? (
                                        <StarRateLegacy
                                            rate={customerQuote2RatingStar}
                                            color="#20baff"
                                        />
                                    ) : (
                                        <StarRateDefault
                                            rate={customerQuote2RatingStar}
                                            color="#20baff"
                                        />
                                    )}

                                    <span className={styles.date}>{customersQuote2Date}</span>
                                </footer>
                            </blockquote>
                        </div>
                        <div className={styles.rate}>
                            {customersQuote2Logo}
                            <div>
                                {theme === 'legacy' ? (
                                    <StarRateLegacy
                                        rate={customersQuote2Rating}
                                        color="#FFC400"
                                    />
                                ) : (
                                    <StarRateDefault
                                        rate={customersQuote2Rating}
                                        color="#FFC400"
                                    />
                                )}
                            </div>
                            <span className={styles.rating}>{customersQuote2Rating} / 5</span>
                        </div>
                    </div>
                </a>
            </div>
            <Awards maxWidthAwards={maxWidthAwards} />
        </div>
    );
};

export interface CustomersProps extends ContentProps {
    container: React.ReactElement;
    customTitle?: JSX.Element;
    themeStyles?: typeof defaultStyles;
}

export default function Customers({
    container,
    themeStyles,
    sectionHeadline,
    quote,
    maxWidthAwards,
    customClass,
}: CustomersProps): JSX.Element {
    return (
        <section className={defaultStyles.customersWrapper}>
            {cloneElement(container, {
                children: (
                    <Content
                        themeStyles={themeStyles}
                        sectionHeadline={sectionHeadline}
                        quote={quote}
                        maxWidthAwards={maxWidthAwards}
                        customClass={customClass}
                    />
                ),
            })}
        </section>
    );
}
