import React from 'react';

import defaultStyles from './MaxHeaderItem.module.css';

export interface MaxHeaderItemProps {
    label: JSX.Element | string;
    themeStyles?: typeof defaultStyles;
}

export default function MaxHeaderItem({ label, themeStyles }: MaxHeaderItemProps): JSX.Element {
    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }
    return <li className={styles.li}>{label}</li>;
}
