import React, { ReactNode } from 'react';

export interface TabProps {
    title?: ReactNode;
    icon?: ReactNode;
    customClassName?: string;
    active?: boolean;
    children: React.ReactNode;
}

// FIXME Tab cmp accepts props and doesn't pass it down

/**
 * 1. What is this approach, why do we accept props and don't pass it down?
 * 2. Why do we wrap <div> in Tab cmp? No sense in this approach - no additional styling nor handlers.
 * 3. The same solution in /components/global/TabsAccordion/Tab/index.tsx
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Tab(props: TabProps): React.ReactElement {
    return <div />;
}
