import React from 'react';

import { useRouter } from 'next/router';
import { GetStaticProps } from 'next/types';

import { MaxThemeContext } from '@context/MaxThemeContex';

import Trans from '@components/Trans';
import Container from '@components/global/Container';
import Seo from '@components/global/Seo';
import Layout from '@components/layouts/Layout';
import FooterLegal from '@components/pages/max/FooterLegal';
import JsonLd from '@components/pages/max/JsonLd';
import MaxHeaderButton from '@components/pages/max/MaxHeader/MaxHeaderButton/themed/default';
import MaxHeader from '@components/pages/max/MaxHeader/themed/default';
import SectionAllInOnePlatform from '@components/pages/max/design2024/SectionAllInOnePlatform';
import AnalysisBusinessBenefits from '@components/pages/max/design2024/SectionAnalysisBusinessBenefits';
import SectionBookADemo from '@components/pages/max/design2024/SectionBookADemo';
import SectionBrands from '@components/pages/max/design2024/SectionBrands';
import SectionCustomers from '@components/pages/max/design2024/SectionCustomers';
import ExpertSupportSupport from '@components/pages/max/design2024/SectionExpertSupport';
import Hero from '@components/pages/max/design2024/SectionHero';
import SectionIncreasedSales from '@components/pages/max/design2024/SectionIncreasedSales';
import SectionSolidIntegrations from '@components/pages/max/design2024/SectionSolidIntegrations';
import BGWrapperLightBlue from '@components/pages/max/design2024/components/BGWrapperLightBlue';
import DeskoptBr from '@components/ui/DeskoptBr';

import getGlobalProps from '@helpers/getGlobalProps';
import getLocale from '@helpers/getLocale';
import getPageLocales from '@helpers/getPageLocales';
import { PropsWithSeoData } from '@helpers/seo';

import socialShareDe from '@public/pages/max/design2024/shareDE.png';
import socialShareDefault from '@public/pages/max/design2024/shareEN.png';
import socialShareEs from '@public/pages/max/design2024/shareES.png';
import socialShareFr from '@public/pages/max/design2024/shareFR.png';
import socialShareIt from '@public/pages/max/design2024/shareIT.png';
import socialSharePl from '@public/pages/max/design2024/sharePL.png';
import socialSharePt from '@public/pages/max/design2024/sharePT.png';

import useTranslation from '@hooks/useTranslation';

/**
 * @ stylesGlobal
 * wywołanie potrzebne dla styli BG (tree shaking)
 */
import stylesGlobal from '@styles/pages/max/body.module.css';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
stylesGlobal;

export default function Max({ seo }: PropsWithSeoData) {
    const { t } = useTranslation('pages/max/design2024/main/index');
    const { locale } = useRouter();

    const getOgImage = (locale: string | undefined): string => {
        switch (locale) {
            case 'pl':
                return socialSharePl.src;
            case 'de':
                return socialShareDe.src;
            case 'fr':
                return socialShareFr.src;
            case 'es':
                return socialShareEs.src;
            case 'it':
                return socialShareIt.src;
            case 'pt':
                return socialSharePt.src;
            default:
                return socialShareDefault.src;
        }
    };

    return (
        <>
            <Seo
                title={t('seo.design2024.title')}
                description={t('seo.design2024.description')}
                ogImage={getOgImage(locale)}
                noindex={!seo.robots.index}
                nofollow={!seo.robots.follow}
                canonical={seo.canonical}
            />
            <JsonLd />
            <MaxThemeContext.Provider value="default">
                <main>
                    <MaxHeader />
                    <Hero container={<Container />} />
                    <SectionBrands container={<Container maxWidth="1140px" />} />
                    <BGWrapperLightBlue>
                        <SectionAllInOnePlatform container={<Container />} />
                        <SectionCustomers
                            container={<Container />}
                            sectionHeadline={
                                <Trans
                                    i18nKey="sectionHeadline"
                                    components={{
                                        span: <span />,
                                        br: <DeskoptBr />,
                                    }}
                                    ns="pages/max/design2024/components/SectionCustomers/index"
                                />
                            }
                        />
                    </BGWrapperLightBlue>
                    <AnalysisBusinessBenefits container={<Container />} />
                    <BGWrapperLightBlue>
                        <SectionIncreasedSales container={<Container />} />
                        <ExpertSupportSupport container={<Container />} />
                        <SectionSolidIntegrations container={<Container maxWidth="1010.76px" />} />
                        <SectionBookADemo container={<Container maxWidth="1140px" />} />
                    </BGWrapperLightBlue>
                </main>
            </MaxThemeContext.Provider>
        </>
    );
}

export const getStaticProps: GetStaticProps = async (context) => {
    const locale = getLocale(context);
    const globalProps = await getGlobalProps(context, '/max');

    return {
        revalidate: 5 * 60,
        props: {
            ...globalProps,
            locale,
        },
    };
};

export const getStaticPaths = async () => {
    const locales = getPageLocales('/max');
    return {
        paths: locales.map((locale) => ({
            params: {
                locale,
            },
        })),
        fallback: false,
    };
};

Max.getLayout = function getLayout(page: JSX.Element) {
    const darkMode = true;

    return (
        <Layout
            headerProps={{
                menuButtonComponent: <MaxHeaderButton type="header" />,
                menuLogIn: false,
                sticky: false,
                additionalHeaderAttribute: 'has-new-layout',
                darkMode,
            }}
            footerProps={{ footerLegalComponent: <FooterLegal darkMode={darkMode} />, darkMode }}
        >
            {page}
        </Layout>
    );
};
