import clsx from 'clsx';

import styles from './TabWrapper.module.css';

export interface TabWrapperProps {
    condition: boolean;
    accordion?: boolean;
    isSelected: boolean;
    className?: string;
}

export function TabWrapper({
    condition = false,
    isSelected = false,
    accordion,
    className,
    children,
}: React.PropsWithChildren<TabWrapperProps>) {
    return condition ? (
        <div
            className={clsx(
                styles['tab-wrapper'],
                isSelected && styles.active,
                accordion && styles.accordion,
                className,
            )}
        >
            {children}
        </div>
    ) : (
        <>{children}</>
    );
}
