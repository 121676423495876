import React from 'react';

import clsx from 'clsx';

import styles from './TabPanel.module.css';

export interface TabPanelProps {
    type: string;
    isActive: boolean;
    isHidden: boolean;
    tabCount: number;
    className?: string;
}

export function TabPanel({
    isHidden,
    tabCount,
    className,
    type,
    isActive,
    children,
}: React.PropsWithChildren<TabPanelProps>) {
    return (
        <div
            role="tabpanel"
            className={clsx(styles.tabpanel, className, styles[type], !isActive && styles.hidden)}
            hidden={isHidden}
            style={tabCount ? { gridColumn: `1 / span ${tabCount}` } : {}}
        >
            {children}
        </div>
    );
}
