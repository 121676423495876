import React from 'react';

import useTranslation from '@hooks/useTranslation';

import Tab1avif from './tab1@1x.avif';
import Tab1png from './tab1@1x.png';
import Tab1webp from './tab1@1x.webp';
import Tab1avif2x from './tab1@2x.avif';
import Tab1png2x from './tab1@2x.png';
import Tab1webp2x from './tab1@2x.webp';

interface TabPictureProps {
    lazy?: boolean;
    fetchpriority?: boolean;
    preload?: boolean;
}

export default function TabPicture({ lazy = true, fetchpriority = false }: TabPictureProps): JSX.Element {
    const ns = 'pages/max/design2024/components/SectionAllInOnePlatform/index';
    const { t } = useTranslation(ns);

    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${Tab1avif.src} 1x, ${Tab1avif2x.src} 2x`}
            />
            <source
                type="image/webp"
                srcSet={`${Tab1webp.src} 1x, ${Tab1webp2x.src} 2x`}
            />
            <source srcSet={`${Tab1png.src} 1x, ${Tab1png2x.src} 2x`} />
            <img
                width={Tab1png.width}
                height={Tab1png.height}
                decoding="async"
                fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                loading={lazy ? 'lazy' : undefined}
                alt={t('tab1ImageAlt')}
                src={Tab1png.src}
            />
        </picture>
    );
}
