import React, { KeyboardEvent, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

import { useMaxThemeContext } from '@context/MaxThemeContex';

import clsx from 'clsx';
import useBreakpoint from 'use-breakpoint';

import { DotActivator } from '../DotActivator';
import { TabProps } from '../Tab';
import { TabPanel } from '../TabPanel';
import { TabWrapper } from '../TabWrapper';
import { BREAKPOINTS } from '../_constants';
import defaultStyles from './Tabs.module.css';

export interface TabsProps {
    type?: 'tabs' | 'dots';
    arrows?: boolean;
    accordion?: boolean;
    classNames?: Record<string, string>;
    children: React.ReactElement<TabProps> | Array<React.ReactElement<TabProps>>;
    tabDirection?: 'row' | 'column';
    themeStyles?: typeof defaultStyles;
    isArrowVisible?: boolean;
}

const TabActivatorDefault = dynamic(() => import('../TabActivator/themed/default'), {
    ssr: true,
});

const TabActivatorLegacy = dynamic(() => import('../TabActivator/themed/legacy'), {
    ssr: true,
});

export function Tabs(props: TabsProps): React.ReactElement {
    const { type = 'tabs' } = props;
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
    const {
        classNames = {},
        arrows = false,
        accordion = false,
        tabDirection = 'row',
        themeStyles,
        isArrowVisible = false,
    } = props;
    const tabCount = React.Children.count(props.children);
    const [focus, setFocus] = useState(false);
    const theme = useMaxThemeContext() ? useMaxThemeContext() : 'legacy';

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce(
            (accumulator, className) =>
                Object.assign({}, accumulator, {
                    [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' '),
                }),
            {},
        );
    } else {
        styles = defaultStyles;
    }

    const [selectedIndexes, setSelectedIndexes] = React.useState(() => {
        const activeIndex: number[] = [];
        React.Children.forEach(props.children, (tab, index) => {
            if (tab.props.active) {
                activeIndex.push(index);
            }
        });
        return activeIndex;
    });

    const handleTabOnClick = React.useCallback(
        (index: number) => {
            if (accordion && breakpoint === 'mobile') {
                if (!selectedIndexes.includes(index)) {
                    setSelectedIndexes([...selectedIndexes, index]);
                } else {
                    setSelectedIndexes(selectedIndexes.filter((item) => item !== index));
                }
            } else {
                setSelectedIndexes([index]);
            }
            setFocus(true);
        },
        [accordion, selectedIndexes, breakpoint],
    );

    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
            handlePrevClick();
        }
        if (event.key === 'ArrowRight') {
            handleNextClick();
        }
    };

    const handleNextClick = React.useCallback(() => {
        const actualIndex = selectedIndexes[0];
        const nextIndex = (actualIndex + 1 + tabCount) % tabCount;
        setSelectedIndexes([nextIndex]);
    }, [selectedIndexes, tabCount]);

    const handlePrevClick = React.useCallback(() => {
        const actualIndex = selectedIndexes[0];
        const nextIndex = (actualIndex - 1 + tabCount) % tabCount;
        setSelectedIndexes([nextIndex]);
    }, [selectedIndexes, tabCount]);

    useEffect(() => {
        if (breakpoint !== 'mobile') {
            setSelectedIndexes([0]);
        }
    }, [breakpoint]);

    return (
        <div className={clsx(styles['tabs'], classNames['tabs'])}>
            {(arrows && isArrowVisible) || (arrows && breakpoint !== 'mobile') ? (
                <>
                    <button
                        onClick={handlePrevClick}
                        className={clsx(
                            styles.arrow,
                            styles.prev,
                            isArrowVisible ? styles.isMobileVisible : '',
                            classNames['prev'],
                        )}
                    />
                    <button
                        onClick={handleNextClick}
                        className={clsx(
                            styles.arrow,
                            styles.next,
                            isArrowVisible ? styles.isMobileVisible : '',
                            classNames['next'],
                        )}
                    />
                </>
            ) : null}
            <div
                role="tablist"
                className={clsx(styles.tablist, styles[`type-${type}`], classNames.tablist)}
                onKeyDown={handleKeyPress}
                style={type === 'tabs' ? { gridTemplateColumns: `repeat(${tabCount}, 1fr)` } : {}}
                tabIndex={0}
            >
                {type === 'dots' ? (
                    <div className={clsx(styles.dotlist, classNames.dotlist)}>
                        {React.Children.map(props.children, (tab, index) => {
                            const isSelected = selectedIndexes.includes(index);

                            return (
                                <DotActivator
                                    {...tab.props}
                                    isSelected={isSelected}
                                    index={index}
                                    className={tab.props.customClassName ?? classNames.dot}
                                    focusIfSelected={breakpoint !== 'mobile' && focus}
                                    onClick={handleTabOnClick}
                                />
                            );
                        })}
                    </div>
                ) : null}
                {React.Children.map(props.children, (tab, index) => {
                    const isSelected = selectedIndexes.includes(index);
                    return (
                        <TabWrapper
                            isSelected={isSelected}
                            condition={type === 'tabs' && breakpoint === 'mobile'}
                            accordion={accordion}
                            className={classNames.accordion}
                        >
                            <>
                                {type === 'tabs' ? (
                                    <>
                                        {theme === 'default' ? (
                                            <TabActivatorDefault
                                                {...tab.props}
                                                isSelected={isSelected}
                                                index={index}
                                                className={tab.props.customClassName ?? classNames.tab}
                                                focusIfSelected={breakpoint !== 'mobile' && focus}
                                                onClick={handleTabOnClick}
                                                theme={theme}
                                                tabDirection={tabDirection}
                                            />
                                        ) : (
                                            <TabActivatorLegacy
                                                {...tab.props}
                                                isSelected={isSelected}
                                                index={index}
                                                className={tab.props.customClassName ?? classNames.tab}
                                                focusIfSelected={breakpoint !== 'mobile' && focus}
                                                onClick={handleTabOnClick}
                                                theme={theme}
                                                tabDirection={tabDirection}
                                            />
                                        )}
                                    </>
                                ) : null}
                                <TabPanel
                                    type={type}
                                    tabCount={type === 'tabs' ? tabCount : 0}
                                    isActive={isSelected}
                                    isHidden={type === 'tabs' && breakpoint === 'mobile' && !isSelected}
                                    className={classNames.tabpanel}
                                >
                                    {tab.props.children}
                                </TabPanel>
                            </>
                        </TabWrapper>
                    );
                })}
            </div>
        </div>
    );
}
